import React, { useState } from 'react'
import { useMutation } from "@apollo/client";
import { SINGLE_UPLOAD_MUTATION } from '../graph_client/schema/upload'
import l from '../lib/logger'

export const UploadFile = () => {
  const [uploadFileMutation] = useMutation(SINGLE_UPLOAD_MUTATION);
  //const [fileSelected, setFileSelected] = useState()

  const onChange = ({
    target: {
      //@ts-ignore
      validity,
      //@ts-ignore
      files,
    },
  }) => {
    console.log('files------')
    if (validity.valid && files && files[0]) {
      uploadFileMutation({ variables: { file: files[0]} })
      .then(() => {
        l.debug('Uploading file', files[0])
      })
      .catch(e => {
        l.error('Error uploading file', e)
      })
    }
  }

  return <input type="file" onChange={onChange} />
}