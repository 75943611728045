import Redactor from 'redact-secrets'
import isObject from 'lodash.isobject'
  
export type LogLevels =
| 'verbose'
| 'debug'
| 'info'
| 'warn'
| 'error'
| 'fatal'
| 'off'
| 'default'

export type loggingTypes = 'console' // TODO: add more in future as needed

export type LoggerOptions = {
moduleName: string
logLevel: LogLevels
loggerType?: loggingTypes
}  

export const getDefaultLogLevel = (): LogLevels => {
  const t = process.env.DEFAULT_LOG_LEVEL || 'info'
  return isLogLevel(t) ? t : 'info'
}

const isLogLevel = (level: string): level is LogLevels => {
  const levels: LogLevels[] = [
    'verbose',
    'debug',
    'info',
    'warn',
    'error',
    'fatal'
  ]
  return levels.includes(level as LogLevels)
}

const defaultLogLevel = getDefaultLogLevel()

const redact = Redactor('[REDACTED]')

export const formatObject = (d: any): any => {
  if (d === undefined || d === null) return '' //to ensure we don't get undesired output in logs
  if (!isObject(d)) return d
  return JSON.stringify(redactObject(d))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const redactObject = (d: any): any => {
  try {
    if (typeof d === 'object') return redact.map(d)
    else return d
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Error redacting object `, e)
    return d
  }
}

export const shouldLog = (l: LogLevels) => {
  const levels = ['verbose', 'debug', 'info', 'warn', 'error', 'fatal', 'off']
  return levels.indexOf(l) >= levels.indexOf(defaultLogLevel) ? true : false
}

const verbose = (msg: string, info: any = undefined) => {
  if (!shouldLog('verbose')) return
  // eslint-disable-next-line no-console
  console.debug('V ' + msg, formatObject(info))
}

const debug = (msg: string, info: any = undefined) => {
if (!shouldLog('debug')) return
// eslint-disable-next-line no-console
console.debug('D ' + msg, formatObject(info))
}
const info = (msg: string, info: any = undefined) => {
  if (!shouldLog('info')) return

  // eslint-disable-next-line no-console
  console.info('I ' + msg, formatObject(info))
}
const warn = (msg: string, info: any = undefined) => {
  if (!shouldLog('warn')) return

  // eslint-disable-next-line no-console
  console.warn('W ' + msg, formatObject(info))
}

const error = (msg: string, info: any = undefined) => {
  if (!shouldLog('error')) return

  // eslint-disable-next-line no-console
  console.error('E ' + msg, formatObject(info))
}

const l = {
  verbose,
  debug,
  info,
  warn,
  error
}

export default l
