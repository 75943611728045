import { InMemoryCache, ApolloClient } from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"

export const client = new ApolloClient({
	uri: 'https://api.subjective-wine.ldev.shadowtechllc.net/graphql',
	cache: new InMemoryCache(),
	link: createUploadLink({
		uri: 'https://api.subjective-wine.ldev.shadowtechllc.net/graphql',
		headers: {
			"Apollo-Require-Preflight": "true",
		},
	}),
})