
import { ApolloProvider } from "@apollo/client";
import { client } from './client'
import { PropsWithChildren } from "react";

//better way?
export const Graph = ({children} : PropsWithChildren) => {
  return(
    <ApolloProvider client={client}>
        { children }
    </ApolloProvider>
  )
}